// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import type {
	AccountingDocument,
	AccountingDocumentLineItem,
	Enum,
	EnumItem,
	AccountingDocumentPatchPreferredBranchAndProject,
	FinancialAccount,
	VatRate,
} from 'types'
import { Number_PaymentType } from 'types/convertor'
import { withAccountingDocumentActions, withTranslate, type WithTranslateProps } from 'wrappers'
import SelectNext from 'components/select-next'
import type { OptionType } from 'components/select-next'
import type { WithAccountingDocumentActionsProps } from 'wrappers'
import IconButton from 'components/icon-button'
import NavigationArrowDownward from 'components/svg-icons/navigation/arrow-downward'
import Valid from 'components/svg-icons/trivi/input/valid'
// $FlowFixMe
import React, { useCallback, useMemo, useState } from 'react'
import { AccountingDocumentTypeNumber_isCashRegister } from 'types/operations'
import { getBasicFinancialAccountsOptions } from '../../domain/financial-accounts'
import { filterVatRates } from '../../domain/vat-rate'
import memoize from 'memoize-one'

type Props = {|
	...WithTranslateProps,
	...WithAccountingDocumentActionsProps,
	accDoc: ?AccountingDocument,
	cashRegister: ?string,
	branch: ?string,
	project: ?string,
	inline?: boolean,
	isEditable?: boolean,
	isExtractable?: ?boolean,
	isExtracting?: ?boolean,
	isUploaded?: ?boolean,
	branches: ?Enum,
	projects: ?Enum,
	financialAccounts: Array<FinancialAccount>,
	vatRates: null | Array<VatRate>,
	isInternal: boolean,
	isIdentified: boolean,
	isCzechContact: boolean,
	isVatFree: boolean,
	onBranchChange: (accDocId: string, newDefaults: AccountingDocumentPatchPreferredBranchAndProject) => void,
	onProjectChange: (accDocId: string, newDefaults: AccountingDocumentPatchPreferredBranchAndProject) => void,
	onSetBranchToDefault: (accDocId: string, newLineItem: AccountingDocumentLineItem) => void,
	onSetProjectToDefault: (accDocId: string, newLineItem: AccountingDocumentLineItem) => void,
|}

function ClassificationInfo(props: Props) {
	const {
		accDoc,
		inline,
		cashRegister,
		branch,
		project,
		t,
		branches,
		projects,
		isUploaded,
		onBranchChange,
		onProjectChange,
		onSetProjectToDefault,
		onSetBranchToDefault,
		vatRates,
		isIdentified,
		isCzechContact,
		isVatFree,
	} = props
	const paymentTypeNumber: ?number = accDoc && accDoc.paymentType
	const cashText: string = t(`accountingDocument.paymentTypes.${Number_PaymentType(paymentTypeNumber)}`)
	const styles = getStyles(props)

	const [finAcc, setFinAcc] = useState(null)
	const [vatRate, setVatRate] = useState(null)

	const isAllPreferredBranch = useMemo(() => {
		return (
			accDoc &&
			accDoc.lineItems &&
			accDoc.lineItems.every((lineItem: AccountingDocumentLineItem) => lineItem.branch === accDoc.preferredBranch)
		)
	}, [accDoc])

	const isAllPreferredProject = useMemo(() => {
		return (
			accDoc &&
			accDoc.lineItems &&
			accDoc.lineItems.every((lineItem: AccountingDocumentLineItem) => lineItem.project === accDoc.preferredProject)
		)
	}, [accDoc])

	const isAllPreferredFinAcc = useMemo(() => {
		return (
			accDoc &&
			accDoc.lineItems &&
			accDoc.lineItems.every((lineItem: AccountingDocumentLineItem) => lineItem.finAccount === finAcc)
		)
	}, [finAcc, accDoc])

	const isAllPreferredVatRate = useMemo(() => {
		return (isIdentified && isCzechContact) || isVatFree
			? true
			: accDoc &&
					accDoc.lineItems &&
					accDoc.lineItems.every((lineItem: AccountingDocumentLineItem) => lineItem.vatRateId === vatRate)
	}, [vatRate, accDoc, isIdentified, isCzechContact, isVatFree])

	const getFinAccountOptions = memoize((accounts: Array<FinancialAccount>) => {
		return getBasicFinancialAccountsOptions(accounts)
	})

	const hasBranches = useMemo(() => {
		return branches && branches.length > 0
	}, [branches])

	const hasProjects = useMemo(() => {
		return projects && projects.length > 0
	}, [projects])

	const getOptions = (items: ?Enum): Array<any> => {
		return (items ?? []).map((item: EnumItem) => {
			return { value: item.key, label: item.value }
		})
	}

	const getVatRateOptions = (items: VatRate[]): Array<any> => {
		return (items ?? []).map((item: VatRate) => {
			return { value: item.id, label: item.text }
		})
	}

	const getFilteredVatRates = memoize((vatRates?: Array<VatRate> | null, financialAccount?: string): Array<VatRate> =>
		filterVatRates(vatRates, financialAccount),
	)

	const handleBranchChange = useCallback(
		(option: ?OptionType) => {
			if (accDoc && accDoc.id) {
				onBranchChange(accDoc.id, {
					preferredBranch: (option && option.value) ?? undefined,
					preferredProject: accDoc.preferredProject,
				})
			}
		},
		[accDoc, onBranchChange],
	)

	const handleProjectChange = useCallback(
		(option: ?OptionType) => {
			if (accDoc && accDoc.id) {
				onProjectChange(accDoc.id, {
					preferredProject: (option && option.value) ?? undefined,
					preferredBranch: accDoc.preferredBranch,
				})
			}
		},
		[accDoc, onProjectChange],
	)

	const handleFinAccChange = useCallback(
		(option: ?OptionType) => {
			if (accDoc && accDoc.id) {
				setFinAcc((option && option.value) ?? null)
				if (!option || !option.value) {
					setVatRate(null)
				}
			}
		},
		[accDoc],
	)

	const handleVatRateChange = useCallback(
		(option: ?OptionType) => {
			if (accDoc && accDoc.id) {
				setVatRate((option && option.value) ?? null)
			}
		},
		[accDoc],
	)

	const setAllLinesToDefaultBranch = useCallback(() => {
		if (accDoc && accDoc.lineItems) {
			accDoc.lineItems.forEach((lineItem: AccountingDocumentLineItem) => {
				if ((lineItem.branch && lineItem.branch !== accDoc.preferredBranch) || !lineItem.branch) {
					lineItem.branch = accDoc.preferredBranch
					accDoc.id && onSetBranchToDefault(accDoc.id, lineItem)
				}
			})
		}
	}, [accDoc, onSetBranchToDefault])

	const setAllLinesToDefaultProject = useCallback(() => {
		if (accDoc && accDoc.lineItems) {
			accDoc.lineItems.forEach((lineItem: AccountingDocumentLineItem) => {
				if ((lineItem.project && lineItem.project !== accDoc.preferredProject) || !lineItem.project) {
					lineItem.project = accDoc.preferredProject
					accDoc.id && onSetProjectToDefault(accDoc.id, lineItem)
				}
			})
		}
	}, [accDoc, onSetProjectToDefault])

	const hideInCashRegister = accDoc && AccountingDocumentTypeNumber_isCashRegister(accDoc.type) && accDoc.type !== 8

	const setAllLinesToDefaultFinAccVatRate = useCallback(() => {
		const completeVatRate = getFilteredVatRates(vatRates, finAcc).find((vr: VatRate) => vr.id === vatRate)
		if (accDoc && accDoc.lineItems) {
			accDoc.lineItems.forEach((lineItem: AccountingDocumentLineItem) => {
				lineItem.finAccount = finAcc ?? undefined
				lineItem.vatRate = (completeVatRate && completeVatRate.vatRate) ?? undefined
				lineItem.vatRateId = (completeVatRate && completeVatRate.id) ?? undefined
				lineItem.vatRateType = (completeVatRate && completeVatRate.type) ?? undefined
				accDoc.id && onSetBranchToDefault(accDoc.id, lineItem)
			})
		}
	}, [accDoc, onSetBranchToDefault, finAcc, vatRate, getFilteredVatRates, vatRates])

	const formatOptionLabel = (option: OptionType) => {
		if (option && option.value && option.label) {
			return `${option.value} ${option.label}`
		}
	}

	return (
		<div css={styles.root}>
			<div
				style={
					hasProjects && hasBranches && !props.isExtractable
						? { ...styles.infoRow, ...styles.alignRight }
						: styles.infoRow
				}
			>
				{!inline && props.isExtractable && (
					<div css={styles.infoItem}>
						{t('invoice.classification.cash')}: <br />
						<div css={styles.infoItemSubText}>{cashText}</div>
					</div>
				)}
				{cashRegister && props.isEditable && props.isExtractable && isUploaded && (
					<div css={styles.infoItemShort}>
						{t('invoice.classification.cashRegister')}: <br />
						<div css={styles.infoItemSubText}>{cashRegister}</div>
					</div>
				)}
				{cashRegister && !props.isEditable && (
					<div css={styles.infoItem}>{`${t('invoice.classification.cashRegister')}: ${cashRegister}`}</div>
				)}
				{!inline && accDoc && accDoc.categoryName && (
					<div css={styles.infoItemShort}>
						{t('invoice.classification.category')}: <br />
						<div css={styles.infoItemSubText}>{accDoc.categoryName}</div>
					</div>
				)}
				{hasBranches && !isUploaded && !hideInCashRegister && (
					<>
						<div css={props.isEditable ? styles.infoItemInputWithIcon : styles.infoItem}>
							{props.isEditable ? (
								<SelectNext
									isClearable
									isSearchable
									label={
										props.isExtracting
											? t('invoice.classification.branch')
											: t('invoice.classification.branchShortened')
									}
									autoTestId="upload-list-item-branch-select"
									options={getOptions(branches)}
									onChange={handleBranchChange}
									value={branch}
									compact
									labelInRow={!props.isExtracting}
								/>
							) : (
								branch && `${t('invoice.classification.branch')}: ${branch}`
							)}
						</div>
						{isAllPreferredBranch && props.isEditable ? (
							<div css={styles.infoItemIcon}>
								<IconButton tooltip={t('invoice.classification.isDefaultBranch')}>
									<Valid />
								</IconButton>
							</div>
						) : props.isEditable ? (
							<div css={styles.infoItemIcon}>
								<IconButton
									onClick={setAllLinesToDefaultBranch}
									tooltip={t('invoice.classification.setToDefaultBranch')}
								>
									<NavigationArrowDownward />
								</IconButton>
							</div>
						) : null}
					</>
				)}
				{hasProjects && !isUploaded && !hideInCashRegister && (
					<>
						<div css={props.isEditable ? styles.infoItemInputWithIcon : styles.infoItem}>
							{props.isEditable ? (
								<SelectNext
									isClearable
									isSearchable
									label={
										props.isExtracting
											? t('invoice.classification.project')
											: t('invoice.classification.projectShortened')
									}
									autoTestId="upload-list-item-project-select"
									options={getOptions(projects)}
									onChange={handleProjectChange}
									value={project}
									compact
									labelInRow={!props.isExtracting}
								/>
							) : (
								project && `${t('invoice.classification.project')}: ${project}`
							)}
						</div>
						{isAllPreferredProject && props.isEditable ? (
							<div css={styles.infoItemIconLast}>
								<IconButton tooltip={t('invoice.classification.isDefaultProject')}>
									<Valid />
								</IconButton>
							</div>
						) : props.isEditable ? (
							<div css={styles.infoItemIconLast}>
								<IconButton
									onClick={setAllLinesToDefaultProject}
									tooltip={t('invoice.classification.setToDefaultProject')}
								>
									<NavigationArrowDownward />
								</IconButton>
							</div>
						) : null}
					</>
				)}
			</div>
			{props.isInternal ? (
				<div style={hasProjects && hasBranches ? { ...styles.infoRowLast, ...styles.alignRight } : styles.infoRowLast}>
					{!props.isExtractable && !hideInCashRegister && (
						<>
							<div css={props.isEditable ? styles.infoItemInputWithIconLong : styles.infoItem}>
								{props.isEditable ? (
									<SelectNext
										isClearable
										isSearchable
										label={t('invoice.classification.finAcc')}
										autoTestId="upload-list-item-finAcc-select"
										options={getFinAccountOptions(props.financialAccounts)}
										onChange={handleFinAccChange}
										value={finAcc}
										compact
										formatOptionLabel={formatOptionLabel}
										labelInRow={!props.isExtracting}
									/>
								) : (
									finAcc && `${t('invoice.classification.finAcc')}: ${finAcc}`
								)}
							</div>
							{(props.isIdentified && props.isCzechContact) || props.isVatFree ? null : (
								<>
									<div css={props.isEditable ? styles.infoItemInputWithIconShort : styles.infoItem}>
										{props.isEditable ? (
											<SelectNext
												isClearable
												isSearchable
												label={t('invoice.classification.vatRate')}
												autoTestId="upload-list-item-vatRate-select"
												options={getVatRateOptions(getFilteredVatRates(props.vatRates, finAcc))}
												onChange={handleVatRateChange}
												value={vatRate}
												compact
												disabled={!finAcc}
												labelInRow={!props.isExtracting}
											/>
										) : (
											finAcc && `${t('invoice.classification.vatRate')}: ${finAcc}`
										)}
									</div>
								</>
							)}
							{isAllPreferredFinAcc && isAllPreferredVatRate && props.isEditable ? (
								<div css={styles.infoItemIconLast}>
									<IconButton tooltip={t('invoice.classification.isDefaultFinAcc')}>
										<Valid />
									</IconButton>
								</div>
							) : props.isEditable &&
							  ((vatRate && finAcc) ||
									(!vatRate && !finAcc) ||
									(props.isIdentified && props.isCzechContact) ||
									props.isVatFree) ? (
								<div css={styles.infoItemIconLast}>
									<IconButton
										onClick={setAllLinesToDefaultFinAccVatRate}
										tooltip={t('invoice.classification.setToDefaultFinAcc')}
									>
										<NavigationArrowDownward />
									</IconButton>
								</div>
							) : null}
						</>
					)}
				</div>
			) : null}
		</div>
	)
}

function getStyles(props: Props) {
	return {
		root: {
			lineHeight: '16px',
			minHeight: 16,
			marginBottom: 20,
		},
		infoRow: {
			display: 'flex',
			flexDirection: 'row',
			fontSize: 12,
			opacity: props.inline ? 0.6 : 1,
			flexWrap: 'wrap',
			paddingBottom: props.isEditable ? '15px' : undefined,
		},
		infoRowLast: {
			display: 'flex',
			flexDirection: 'row',
			fontSize: 12,
			opacity: props.inline ? 0.6 : 1,
			flexWrap: 'wrap',
			paddingBottom: props.isEditable ? '35px' : undefined,
		},
		alignRight: {
			justifyContent: props.isEditable ? 'flex-start' : undefined,
		},
		infoItem: {
			width: props.inline ? 160 : 110,
			marginTop: 2,
			marginRight: props.inline ? 15 : 25,
			flex: '0 0 auto',
			fontSize: props.inline ? 12 : 14,
		},
		infoItemShort: {
			width: props.inline ? 160 : props.isUploaded ? undefined : 110,
			marginTop: 2,
			marginRight: 15,
			flex: props.isUploaded ? '0 0 35%' : !props.isExtractable ? '0 0 20%' : '0 0 auto',
			fontSize: props.inline ? 12 : 14,
		},
		infoItemLast: {
			width: 210,
			marginTop: 10,
			marginRight: 1,
			marginBottom: 10,
			flex: '0 0 auto',
			fontSize: props.inline ? 12 : 14,
		},
		infoItemSubText: {
			fontSize: 14,
			marginTop: !props.isExtracting ? 3 : 10,
		},
		infoItemIcon: {
			marginRight: 15,
			marginTop: !props.isExtractable ? -5 : 19,
			flex: '0 0 auto',
		},
		infoItemIconLast: {
			marginRight: 1,
			marginTop: !props.isExtractable ? -5 : 19,
			flex: '0 0 auto',
		},
		infoItemInput: {
			width: '170px',
			marginRight: props.inline ? 15 : 40,
			flex: '0 0 auto',
		},
		infoItemInputWithIcon: {
			width: '170px',
			marginRight: 5,
			flex: !props.isExtractable ? '0 0 30%' : '0 0 auto',
		},
		infoItemInputWithIconLong: {
			width: !props.isExtractable ? '280px' : '250px',
			marginRight: 5,
			flex: !props.isExtractable ? '0 0 60.5%' : '0 0 auto',
		},
		infoItemInputWithIconShort: {
			width: !props.isExtractable ? '160px' : '130px',
			marginRight: 5,
			marginLeft: 15,
			flex: !props.isExtractable ? '0 0 30.3%' : '0 0 auto',
		},
	}
}

export default withTranslate(withAccountingDocumentActions(ClassificationInfo))

/* @flow */

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import type { Contact, ContactAddress, WithRouterProps, MultiAction } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import DeleteDialog from 'components/delete-dialog'
import ContactAdvancedFilter from './contact-advanced-filter'
import { getContactAddressByType } from 'modules/address-book/domain/contact-address'
import { DataGrid as DataGridNext, type DataGridProps as DataGridNextProps } from 'modules/data-grid-next/containers'
import { Column } from 'modules/data-grid-next/components'
import type { Action as DataGridAction } from 'modules/data-grid/types'
import TriviLink from 'modules/common/components/trivi-link'
import { addressBookRoute } from 'modules/address-book/routing/routes'
import { formatContactAddress } from 'utils/formatters/address-formatter'
import Delete from 'components/svg-icons/action/delete'
import Edit from 'components/svg-icons/image/edit'
import styles from './contact-grid.css'
import { EMPTY_ARRAY, MULTI_ACTION_TYPE_CLEAR_SELECTION } from 'trivi-constants'

const ContactDataGrid: React$ComponentType<DataGridNextProps<'contacts'>> = DataGridNext

type Props = {|
	...WithTranslateProps,
	...WithRouterProps,
	canEditContacts: boolean,
	deleteContact: (contactId: string) => void,
|}

class ContactGrid extends Component<Props> {
	actions: Array<DataGridAction<Contact>> = [
		{
			name: this.props.t('addressBook.grid.actions.editContact'),
			type: 'edit_contact',
			icon: <Edit />,
			primary: true,
		},
		{
			name: this.props.t('addressBook.grid.actions.deleteContact'),
			type: 'delete_contact',
			icon: <Delete />,
			primary: true,
		},
	]

	onAction = async (action: string, contact: Contact) => {
		switch (action) {
			case 'delete_contact':
				await DeleteDialog().then(
					async () => {
						if (contact.id) {
							await this.props.deleteContact(contact.id)
						}
					},
					() => {},
				)
				break
			case 'edit_contact':
				contact.id && this.props.history.push(addressBookRoute(contact.id))
				break
		}
	}

	multiActions = [
		{
			name: this.props.t('addressBook.grid.multiActions.deleteContact'),
			type: 'delete_contact',
		},
	]

	onMultiAction = (action: string, rows: Array<Contact>): Promise<?MultiAction> => {
		switch (action) {
			case 'delete_contact':
				return DeleteDialog().then(
					() => {
						rows.forEach((contact: Contact) => {
							contact.id && this.props.deleteContact(contact.id)
						})
						return MULTI_ACTION_TYPE_CLEAR_SELECTION
					},
					() => {},
				)
		}
		return Promise.resolve()
	}

	companyNameRenderer = (value: string, row: Contact) => {
		const { firstName, lastName, companyName, id } = row
		const companyIsEmpty = companyName === null || companyName === ''

		return (
			<span>
				<TriviLink routeFn={addressBookRoute} routeParams={[id || '']}>
					{!companyIsEmpty ? (
						companyName
					) : (
						<span className={styles.name}>{`${firstName || ''} ${lastName || ''}`}</span>
					)}
					{!companyIsEmpty && <br />}
					{!companyIsEmpty && <span className={styles.name}>{`${firstName || ''} ${lastName || ''}`}</span>}{' '}
				</TriviLink>
			</span>
		)
	}

	addressesRenderer = (addresses: Array<ContactAddress>) => {
		if (addresses && addresses.length) {
			const compact = addresses.length > 1

			return (
				<div>
					{formatContactAddress(
						getContactAddressByType(addresses, 1),
						compact,
						this.props.t('contactEdit.edit.billingAddress'),
					)}
					{formatContactAddress(
						getContactAddressByType(addresses, 2),
						compact,
						this.props.t('contactEdit.edit.deliveryAddress'),
					)}
				</div>
			)
		} else return null
	}

	icRenderer = (ic: ?string) => {
		return <div className={styles.icDicColumn}>{ic}</div>
	}

	dicRenderer = (dic: ?string) => {
		return <span className={styles.icDicColumn}>{dic}</span>
	}

	render() {
		const { t, canEditContacts } = this.props

		return (
			<div>
				<ContactAdvancedFilter canEditContacts={canEditContacts} />
				<ContactDataGrid
					id="address-book"
					dataType="contacts"
					filterId="contacts"
					actions={canEditContacts ? this.actions : EMPTY_ARRAY}
					onAction={this.onAction}
					multiActions={this.multiActions}
					onMultiAction={this.onMultiAction}
					checkAllOption
					selectDisabled={!canEditContacts}
				>
					<Column
						title={t('addressBook.grid.columns.name')}
						width="30%"
						columnId="companyName"
						key="companyName"
						render={this.companyNameRenderer}
						sortable
						defaultSortDirection="ASC"
					/>
					<Column
						title={t('addressBook.grid.columns.address')}
						width="40%"
						columnId="addresses"
						key="addresses"
						render={this.addressesRenderer}
					/>
					<Column
						title={t('addressBook.grid.columns.companyRegNo')}
						width="15%"
						columnId="companyRegNo"
						key="companyRegNo"
						sortable
						render={this.icRenderer}
					/>
					<Column
						title={t('addressBook.grid.columns.taxId')}
						width="15%"
						columnId="taxId"
						key="taxId"
						sortable
						render={this.dicRenderer}
					/>
				</ContactDataGrid>
			</div>
		)
	}
}

export default withRouter(withTranslate(ContactGrid))

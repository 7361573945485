/* @flow */
/** @jsx jsx */

import type { CardResponse, CardResponseAccountingDocument, CardResponseDocument } from 'types'
import { type WithTranslateProps, withTranslate } from 'wrappers'
import { beginTask, endTask } from 'utils/loader'

import CardButton from './card-button'
import CardIcon from './card-icon'
import ChevronDown from 'components/svg-icons/navigation/chevron-down'
import ChevronUp from 'components/svg-icons/navigation/chevron-up'
import { Component } from 'react'
import { EMPTY_ARRAY } from 'trivi-constants'
import FileDownload from '../svg-icons/file/download'
import FileIcon from 'components/file-icon'
import FullText from 'components/full-text'
import IconMenu from 'components/icon-menu'
import MenuItem from 'components/menu-item'
import RoundCheckbox from 'components/round-checkbox'
import { buildPrivateUrl } from 'lib/apiHandler'
import { colors } from 'variables'
import { downloadRequest as downloadRequestApi } from 'types'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import { multiDownload } from 'helpers/multi-download'

type Props = {|
	...WithTranslateProps,
	data: CardResponse,
	onComplete: (id: ?string, completed: boolean) => void,
	onDocumentClick: (fileId: ?string, uniqueId: ?string, name: ?string) => void,
	onAccDocClick: (id: ?string) => void,
	onPayment: (card: CardResponse) => void,
	onMatch: () => void,
	onAccountDocumentsUploadRemainer: () => void,
|}

class Card extends Component<Props> {
	onComplete = (checked: boolean) => {
		this.props.onComplete(this.props.data.id, checked)
	}

	onPayment = () => {
		this.props.onPayment(this.props.data)
	}

	getFiles = memoize(
		(documents: Array<CardResponseDocument>, accountingDocuments: Array<CardResponseAccountingDocument>) => {
			return [
				...documents.map((document: CardResponseDocument) => {
					return {
						name: document.name,
						icon: (
							<div
								css={{
									position: 'relative',
									marginRight: 5,
									top: -1,
								}}
							>
								<FileIcon fileName={document.name} size={20} />
							</div>
						),
						onClick: () => {
							this.props.onDocumentClick(document.fileId, document.uniqueId, document.name)
						},
					}
				}),
				...accountingDocuments.map((accountingDocument: CardResponseAccountingDocument) => {
					return {
						icon: null,
						name: `${this.props.t('myCompany.card.accountingDocument')} ${accountingDocument.number || ''}`,
						onClick: () => {
							this.props.onAccDocClick(accountingDocument.id)
						},
					}
				}),
			]
		},
	)

	downloadMultipleFiles = async (): Promise<void> => {
		const loaderId = 'downloadMultipleFiles'

		let docFileIds = []
		let accDocFileIds = []

		if (this.props.data.documents && this.props.data.documents.length > 0) {
			docFileIds = this.props.data.documents.map((doc: CardResponseDocument) => doc.fileId)
		}
		if (this.props.data.accountingDocuments && this.props.data.accountingDocuments.length > 0) {
			accDocFileIds = this.props.data.accountingDocuments.map((doc: CardResponseAccountingDocument) => doc.id)
		}

		const fileIds = [...docFileIds, ...accDocFileIds]

		try {
			beginTask(loaderId)
			const downloadTokens = await Promise.all(
				fileIds.map((fileId?: string) => {
					if (fileId) {
						return downloadRequestApi.post({ fileId })
					}
				}),
			)

			if (downloadTokens && downloadTokens.length > 0) {
				const downloadUrls = downloadTokens.map((resp?: { token?: string }) => {
					return (resp && resp.token && buildPrivateUrl(`uploads/${resp.token}`)) || ''
				})
				await multiDownload(downloadUrls)
			}
		} catch (e) {
			// Do nothing
		} finally {
			endTask(loaderId)
		}
	}

	onMultipleDownload = () => {
		this.downloadMultipleFiles()
	}

	renderAttachments = (styles: Object) => {
		const files = this.getFiles(
			this.props.data.documents || EMPTY_ARRAY,
			this.props.data.accountingDocuments || EMPTY_ARRAY,
		)
		if (!files.length) return null
		const mainFile = files[0]

		return (
			<div css={styles.attachments}>
				<div css={styles.hr} />
				<div css={styles.row}>
					<div css={styles.attachment} onClick={mainFile.onClick}>
						{mainFile.icon}
						{mainFile.name && <FullText text={mainFile.name} />}
					</div>
					{files.length > 1 && (
						<div css={styles.menu}>
							<span css={styles.count}>{files.length}</span>
							<IconMenu
								autoTestId="card-files"
								context
								icon={<ChevronDown size={20} />}
								closeIcon={<ChevronUp size={20} />}
							>
								{files.length >= 2 ? (
									<MenuItem
										fontWeight={'bold'}
										icon={<FileDownload />}
										primaryText={this.props.t('myCompany.card.buttons.downloadAllAttachments')}
										onClick={this.onMultipleDownload}
									/>
								) : null}
								{files.map((file: Object) => {
									return <MenuItem icon={file.icon} key={file.name} primaryText={file.name} onClick={file.onClick} />
								})}
							</IconMenu>
						</div>
					)}
				</div>
			</div>
		)
	}

	getCardTitle = () => {
		if (this.props.data.ctaType === 'payment.pay') {
			if (this.props.data.params && this.props.data.params.payment) {
				if (this.props.data.params.payment.amount && this.props.data.params.payment.amount < 0) {
					return this.props.t('myCompany.card.vatTitle.negativeVat')
				} else if (this.props.data.params.payment.amount === 0) {
					return this.props.t('myCompany.card.vatTitle.zeroVat')
				}
			}
		}
		return this.props.data.title
	}

	render() {
		const card = this.props.data
		const isCompleted = !!(card.completion && card.completion.isCompleted)
		const isCompletable = !!(card.completion && card.completion.isCompletable)
		const canBePaid = card.params && card.params.payment && card.params.payment.amount && card.params.payment.amount > 0
		const canBeClicked =
			canBePaid ||
			this.props.data.ctaType === 'unmatchedPayment.match' ||
			this.props.data.ctaType === 'accountingdocument.upload.reminder'
		const styles = this.getStyles(!!(this.props.data.completion && this.props.data.completion.isCompleted))

		return (
			<div css={styles.card}>
				<div css={styles.head}>
					<CardIcon icon={card.icon} size={43} />
					<div css={styles.titleContainer}>
						<div css={styles.title}>{this.getCardTitle()}</div>
						{card.subtitle && <div css={styles.subtitle}>{card.subtitle}</div>}
						{this.props.data.ctaType === 'unmatchedPayment.match' &&
						this.props.data.params &&
						this.props.data.params.unmatchedPaymentCount === 0 ? (
							<div css={styles.unmatchedSubtitle}>{this.props.t('myCompany.card.noUnmatched')}</div>
						) : null}
					</div>
					{(isCompletable || isCompleted) && (
						<div css={styles.checkbox}>
							<RoundCheckbox checked={isCompleted} disabled={!isCompletable} onCheck={this.onComplete} />
						</div>
					)}
				</div>
				<div css={styles.body}>
					<div css={styles.description}>{card.description}</div>
					<CardButton
						type={card.ctaType}
						params={card.params}
						onPayment={this.onPayment}
						onMatch={this.props.onMatch}
						onAccountDocumentsUploadRemainer={this.props.onAccountDocumentsUploadRemainer}
						disabled={isCompleted || !canBeClicked}
					/>
				</div>
				{this.renderAttachments(styles)}
			</div>
		)
	}

	getStyles = memoize((isCompleted: boolean) => {
		return {
			card: {
				display: 'block',
				position: 'relative',
				background: isCompleted ? colors.whiteFaded50 : colors.white,
				transition: isCompleted ? 'all 350ms ease-out 250ms' : 'all 800ms linear',
				boxShadow: isCompleted ? '1px 1px 0 0 rgba(0,0,0,0.04)' : '2px 2px 0 0 rgba(0,0,0,0.08)',
				borderRadius: 3,
				padding: 15,
				height: 250,
			},
			head: {
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				marginBottom: 18,
				height: '27%',
			},
			titleContainer: {
				flex: '1 1 100%',
				marginLeft: 18,
				lineHeight: '18px',
				position: 'relative',
				top: 1,
				opacity: isCompleted ? 0.4 : 1,
				transition: isCompleted ? 'opacity 350ms ease-out 250ms' : 'opacity 800ms linear',
			},
			title: {
				fontSize: 18,
				fontWeight: 600,
			},
			subtitle: {
				fontSize: 13,
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
			},
			unmatchedSubtitle: {
				fontSize: 13,
			},
			checkbox: {
				flex: '0 0 24px',
				marginLeft: 10,
			},
			body: {
				opacity: isCompleted ? 0.4 : 1,
				transition: isCompleted ? 'opacity 350ms ease-out 250ms' : 'opacity 800ms linear',
			},
			description: {
				fontSize: 15,
				lineHeight: '18px',
				marginBottom: 18,
				display: 'block',
				display: '-webkit-box', //eslint-disable-line no-dupe-keys
				maxWidth: '100%',
				height: 36,
				minHeight: 36,
				maxHeight: 36,
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				webkitLineClamp: '2',
				WebkitBoxOrient: 'vertical',
			},
			fileIcon: {
				position: 'relative',
				marginRight: 5,
				top: -1,
			},
			attachments: {
				marginTop: 15,
				borderTop: `1px solid ${colors.grey300}`,
			},
			attachment: {
				flexGrow: 1,
				flexShrink: 1,
				cursor: 'pointer',
				display: 'flex',
				minWidth: 0,
				overflow: 'hidden',
				alignItems: 'center',
				'&:hover': {
					textDecoration: 'underline',
				},
			},
			row: {
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				paddingTop: 12,
				marginBottom: -3,
			},
			menu: {
				display: 'flex',
				alignItems: 'center',
				marginLeft: 10,
			},
			count: {
				pointerEvents: 'none',
				marginRight: '5px',
			},
			filename: {
				width: 1,
				flexGrow: 1,
				wordBreak: 'break-word',
			},
		}
	})
}

export default withTranslate(Card)

/* @flow */

import { connect } from 'react-redux'
import ContactGrid from '../components/contact-grid'
import { deleteContact } from '../actions'
import { refreshGridData } from 'modules/data-grid-next/actions'
import { userHasAccess } from 'permissions'
import type { State } from 'types'

type StateProps = {
	canEditContacts: boolean,
}

const mapStateToProps = (state: State): StateProps => {
	return {
		canEditContacts: userHasAccess(state, 'editContacts'),
	}
}

type DispatchProps = {
	deleteContact: (contactId: string) => Promise<void>,
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
	return {
		deleteContact: async (contactId: string) => {
			await dispatch(deleteContact(contactId))
			await dispatch(refreshGridData('contacts', 'address-book', 'contacts'))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactGrid)

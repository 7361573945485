/* @flow */
/** @jsx jsx */

import type { CardGroup, CardResponse, CardsGrouped, PortalLanguage, WithRouterProps } from 'types'
import { Component, Fragment } from 'react'
import { type WithNotifyProps, type WithTranslateProps, withNotify, withTranslate } from 'wrappers'
import { todoHistoryRoute, todoRoute } from 'modules/dashboard/routing/routes'

import { ASAP } from 'modules/dashboard/constants/cards'
import Button from 'components/button'
import Card from 'components/card'
import ChevronDown from 'components/svg-icons/navigation/chevron-down'
import ChevronUp from 'components/svg-icons/navigation/chevron-up'
import History from 'components/svg-icons/action/history'
import Loader from 'components/loader'
import PaymentsDetailDialog from 'modules/common/containers/payments-detail-dialog'
import SplashScreen from 'modules/dashboard/components/todo/splashscreen'
import { colors } from 'variables'
import { formatToDateString } from 'utils/formatters/date-formatter'
import { getDayName } from 'utils/formatters'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import { openFile } from 'modules/file/domain'
import { withRouter } from 'react-router'

type Props = {|
	...WithNotifyProps,
	...WithTranslateProps,
	...WithRouterProps,
	cards: CardsGrouped,
	historyCards: CardsGrouped,
	uncompletedCardsCount: number,
	loading: boolean,
	canLoadMore: boolean,
	canLoadOlder: boolean,
	language: ?PortalLanguage,
	initialLoadCards: () => void,
	onLoadMore: () => void,
	onLoadHistory: () => void,
	onComplete: (cardId: ?string, completed: boolean, message: ?string) => void,
	onDocumentClick: (fileId: ?string, uniqueId: ?string) => void,
	onAccDocClick: (accountingDocumentId: ?string) => void,
	onMatch: () => void,
	onAccountDocumentsUploadRemainer: () => void,
	isHistory: boolean,
|}

type State = {|
	paymentDetailsCard: ?CardResponse,
	closedDays: { [string]: boolean },
|}

class CardList extends Component<Props, State> {
	state = {
		paymentDetailsCard: null,
		closedDays: {},
	}

	componentDidMount() {
		this.props.initialLoadCards()
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.language && this.props.language !== prevProps.language) {
			this.props.initialLoadCards()
		}
	}

	onRequestClose = () => {
		this.setState({ paymentDetailsCard: null })
	}

	onComplete = (cardId: ?string, completed: boolean) => {
		this.props.onComplete(cardId, completed)
		if (completed) this.props.notify(this.props.t('myCompany.card.checkedCardSnackbar'), 'success')
	}

	onPayment = (card: CardResponse) => {
		this.setState({ paymentDetailsCard: card })
	}

	onMatch = () => {
		this.props.onMatch()
	}

	getDayValue = (date: Date): Date => {
		return new Date(date.toDateString())
	}

	getCloseDay = (day: string) => () => {
		this.setState({
			closedDays: { ...this.state.closedDays, [day]: true },
		})
	}

	getOpenDay = (day: string) => () => {
		this.setState({
			closedDays: { ...this.state.closedDays, [day]: false },
		})
	}

	onLoadMoreClick = () => {
		if (this.props.isHistory) {
			this.props.onLoadHistory()
		} else {
			this.props.onLoadMore()
		}
	}

	onSetViewClick = () => {
		if (this.props.isHistory) {
			this.props.history.push(todoRoute())
		} else {
			this.props.history.push(todoHistoryRoute())
		}
	}

	getStyles = memoize((isLoading: boolean) => {
		return {
			root: {
				position: 'relative',
				minHeight: 200,
				width: '100%',
			},
			container: {
				position: 'relative',
				width: '100%',
			},
			bar: {
				position: 'absolute',
				top: 5,
				bottom: 15,
				left: 0,
				width: 10,
				background: '#EEEEEE',
				borderRadius: 5,
			},
			day: {
				position: 'relative',
				paddingLeft: 50,
				marginBottom: 20,
				'&:before': {
					content: '""',
					position: 'absolute',
					top: 2,
					left: -4,
					background: '#B4B4B4',
					boxShadow: '2px 2px 0 0 rgba(0,0,0,0.08)',
					width: 18,
					height: 18,
					borderRadius: '50%',
				},
			},
			dayHead: {
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				marginBottom: 15,
			},
			today: {
				fontSize: 20,
				fontWeight: 600,
			},
			history: {
				position: 'absolute',
				top: 4,
				right: 0,
				fontSize: 15,
				color: colors.black,
				cursor: isLoading ? 'default' : 'pointer',
				pointerEvents: isLoading ? 'none' : 'all',
				opacity: 0.55,
				transition: 'opacity 150ms linear',
				display: 'flex',
				alignItems: 'center',
				zIndex: 10,
				'&:hover': {
					opacity: 1,
					textDecoration: 'underline',
				},
			},
			historyIcon: {
				marginRight: 10,
				width: 16,
				height: 16,
			},
			date: {
				background: '#B4B4B4',
				borderRadius: 10,
				fontSize: 14,
				fontWeight: 600,
				padding: '2px 8px 2px 15px',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				color: colors.white,
				marginLeft: -22,
				minWidth: 170,
				transition: 'all 150ms linear',
				cursor: 'pointer',
				'&:hover': {
					background: '#a4a4a4',
				},
			},
			cards: {
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				flexWrap: 'wrap',
				marginLeft: -15,
				marginRight: -15,
			},
			card: {
				width: '33.3%',
				minWidth: 250,
				padding: 15,
			},
			loadMore: {
				marginTop: 40,
				marginBottom: 20,
				textAlign: 'center',
			},
		}
	})

	renderDays = memoize((cards: ?CardsGrouped, closedDays: { [string]: boolean }) => {
		if (!cards) return null
		return cards.map((cardGroup: CardGroup) => {
			return this.renderDay(
				cardGroup.title,
				cardGroup.data,
				this.getStyles(this.props.loading),
				closedDays[cardGroup.title],
			)
		})
	})

	onDocClick = (fileId: ?string, uniqueId: ?string, fileName: ?string) => {
		fileId && fileName && openFile(fileId, fileName, 'popupPage')
	}

	renderDay = (title: string, cards: Array<CardResponse>, styles: Object, isClosed: boolean) => {
		const { t } = this.props
		const isToday = title === ASAP

		return (
			<div css={styles.day} key={title}>
				<div css={styles.dayHead}>
					{isToday ? (
						<Fragment>
							<div css={styles.today}>{t('myCompany.card.asap')}</div>
						</Fragment>
					) : (
						<div css={styles.date} onClick={isClosed ? this.getOpenDay(title) : this.getCloseDay(title)}>
							{getDayName(new Date(title))} {formatToDateString(title)}
							{isClosed ? (
								<ChevronDown size={20} color={colors.white} disabled />
							) : (
								<ChevronUp size={20} color={colors.white} disabled />
							)}
						</div>
					)}
				</div>
				{!isClosed && (
					<div css={styles.cards}>
						{cards.map((card: CardResponse) => {
							return (
								<div key={card.id} css={styles.card}>
									<Card
										data={card}
										onComplete={this.onComplete}
										onDocumentClick={this.onDocClick}
										onAccDocClick={this.props.onAccDocClick}
										onMatch={this.onMatch}
										onPayment={this.onPayment}
										onAccountDocumentsUploadRemainer={this.props.onAccountDocumentsUploadRemainer}
									/>
								</div>
							)
						})}
					</div>
				)}
			</div>
		)
	}

	canShowOrLoadHistoryCards = (props: Props) => props.canLoadOlder || props.historyCards.length

	renderViewSwitch() {
		const { t } = this.props
		const styles = this.getStyles(this.props.loading)

		if (!this.props.loading && !this.props.isHistory && !this.canShowOrLoadHistoryCards(this.props)) return null

		return (
			<div onClick={this.onSetViewClick} css={styles.history}>
				<div css={styles.historyIcon}>
					<History color={colors.black} disabled size={16} />
				</div>
				{this.props.loading
					? t('myCompany.card.loading')
					: this.props.isHistory
					? t('myCompany.card.showFuture')
					: t('myCompany.card.showHistory')}
			</div>
		)
	}

	renderEmpty() {
		const styles = this.getStyles(this.props.loading)

		return (
			<div css={styles.root}>
				{this.props.loading ? (
					<Loader transparent visible size={60} />
				) : (
					<div>
						{this.renderViewSwitch()}
						<SplashScreen />
					</div>
				)}
			</div>
		)
	}

	render() {
		const { t, isHistory } = this.props
		const styles = this.getStyles(this.props.loading)

		if (!isHistory && this.props.cards.length === 0 && this.props.uncompletedCardsCount === 0) return this.renderEmpty()

		return (
			<div css={styles.root}>
				<div css={styles.container}>
					<div css={styles.bar} />
					{this.renderViewSwitch()}
					{this.renderDays(isHistory ? this.props.historyCards : this.props.cards, this.state.closedDays)}
				</div>
				{(!isHistory && this.props.canLoadMore) || (isHistory && this.props.canLoadOlder) ? (
					<div css={styles.loadMore}>
						<Button
							wide
							disabled={this.props.loading}
							labelText={isHistory ? t('myCompany.card.loadOlder') : t('myCompany.card.loadMore')}
							onClick={this.onLoadMoreClick}
							autoTestId="card-list-load-more"
						/>
					</div>
				) : null}
				<PaymentsDetailDialog
					card={this.state.paymentDetailsCard}
					open={!!this.state.paymentDetailsCard}
					onRequestClose={this.onRequestClose}
				/>
			</div>
		)
	}
}

export default withRouter(withNotify(withTranslate(CardList)))

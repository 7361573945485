/* @flow */

import type {
	AccountingDocumentListAction,
	CardsGrouped,
	DashboardAction,
	Dispatch,
	PortalLanguage,
	State,
	UserProfile,
} from 'types'
import {
	completeOrganizationCard,
	initialLoadOrganizationCards,
	loadNewOrganizationCards,
	loadOldOrganizationCards,
} from '../actions'
import {
	getFutureCardsByDeadlineDatePure,
	getHistoryCardsByDeadlineDatePure,
	getOrganizationCardsArray,
	getUncompletedCardsCount,
} from '../selectors/cards'
import { showAccountingDocumentRoute, uploadScanRoute } from 'modules/accounting-document/routing/routes'

import CardList from 'components/card-list'
import { connect } from 'react-redux'
import { fileRoute } from 'modules/file/routing/routes'
import { getMyProfile } from 'modules/user/selectors'
import moment from 'moment'
import { push } from 'react-router-redux'
import { resetAccountingDocumentsDirection } from 'modules/accounting-document/actions'
import { todoBankRoute } from '../routing/routes'

type StateProps = {|
	cards: CardsGrouped,
	historyCards: CardsGrouped,
	uncompletedCardsCount: number,
	loading: boolean,
	canLoadMore: boolean,
	canLoadOlder: boolean,
	language: ?PortalLanguage,
|}

const date = moment()
	.startOf('day')
	.toISOString()

const mapStateToProps = (state: State): StateProps => {
	const cards = getOrganizationCardsArray(state)
	const user: ?UserProfile = getMyProfile(state)
	return {
		cards: cards && getFutureCardsByDeadlineDatePure(cards, date),
		historyCards: cards && getHistoryCardsByDeadlineDatePure(cards, date),
		uncompletedCardsCount: getUncompletedCardsCount(state),
		loading: state.dashboard.cards.loading,
		canLoadMore: !state.dashboard.cards.allFutureCardsLoaded,
		canLoadOlder: !state.dashboard.cards.allHistoryCardsLoaded,
		language: user && user.language,
	}
}

type DispatchProps = {|
	initialLoadCards: () => void,
	onLoadMore: () => void,
	onLoadHistory: () => void,
	onComplete: (cardId: ?string, completed: boolean, message: ?string) => void,
	onDocumentClick: (fileId: ?string, uniqueId: ?string) => void,
	onAccDocClick: (accountingDocumentId: ?string) => void,
	onMatch: () => void,
	onAccountDocumentsUploadRemainer: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<DashboardAction | AccountingDocumentListAction>): DispatchProps => {
	return {
		initialLoadCards: () => {
			dispatch(initialLoadOrganizationCards())
		},
		onLoadMore: () => {
			dispatch(loadNewOrganizationCards(7))
		},
		onLoadHistory: () => {
			dispatch(loadOldOrganizationCards(7))
		},
		onComplete: (cardId: ?string, completed: boolean) => {
			cardId && dispatch(completeOrganizationCard(cardId, completed))
		},
		onDocumentClick: (fileId: ?string) => {
			fileId && dispatch(push(fileRoute(fileId)))
		},
		onAccDocClick: (accountingDocumentId: ?string) => {
			dispatch(resetAccountingDocumentsDirection())
			accountingDocumentId && dispatch(push(showAccountingDocumentRoute(accountingDocumentId)))
		},
		onMatch: () => {
			dispatch(push(todoBankRoute()))
		},
		onAccountDocumentsUploadRemainer: () => {
			dispatch(push(uploadScanRoute()))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CardList)

/* @flow */

import { connect } from 'react-redux'
import type {
	AccountingDocument,
	AccountingDocumentLineItem,
	AccountingDocumentReduxAction,
	CommonAction,
	Dispatch,
	Enum,
	OrganizationAction,
	State,
	UploadScanAction,
	AccountingDocumentPatchPreferredBranchAndProject,
	FinancialAccount,
	AccountingDocumentType,
	AccountingDocumentDirection,
	VatRate,
} from 'types'
import ClassificationInfo from 'modules/accounting-document/components/invoice-elements/classification-info'
import { findCashRegister } from 'modules/cash-register/selectors'
import {
	getAccountingDocumentBranch,
	getAccountingDocumentContact,
	getAccountingDocumentProject,
	getAccountingDocumentVatRates,
	getVatRates,
} from 'modules/accounting-document/selectors'
import { getFinancialAccounts, getOrganizationBranches, getOrganizationProjects } from '../../../organization/selectors'
import { setAccDocDefaultBranchAndProject, updateAccountingDocumentLineItem } from '../../actions'
import { generateKeyFromFinancialAccountsGetParams } from '../../../organization/utils'
import {
	AccountingDocumentType_Number,
	AccountingDocumentDirection_Number,
	PayerTaxType_PayerTaxName,
	PayerTaxName_PayerTaxType,
} from 'types/convertor'
import { loadFinancialAccounts } from 'modules/organization/actions'
import withAutoload from 'wrappers/with-autoload'
import { EMPTY_ARRAY } from 'trivi-constants'
import { currentUserIsInternal } from '../../../user/selectors'
import { getIsOrganizationVatFree, getPayerTaxType } from '../../../settings/selectors'

type OwnProps = {|
	accDoc: ?AccountingDocument,
	inline?: boolean,
	isEditable?: boolean,
	isExtractable?: ?boolean,
	isExtracting?: ?boolean,
	isUploaded?: ?boolean,
|}

type StateProps = {|
	branch: ?string,
	project: ?string,
	cashRegister: ?string,
	branches: ?Enum,
	projects: ?Enum,
	financialAccounts: Array<FinancialAccount>,
	vatRates: null | Array<VatRate>,
	isInternal: boolean,
	isIdentified: boolean,
	isCzechContact: boolean,
	isVatFree: boolean,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const payerTaxType = getPayerTaxType(state)
	const isIdentified = payerTaxType === 2

	const contact = ownProps.accDoc && ownProps.accDoc.id && getAccountingDocumentContact(state, ownProps.accDoc.id)
	const isCzechContact = (contact && contact.country && contact.country === 'CZ') || false

	const branch = ownProps.accDoc && getAccountingDocumentBranch(state, ownProps.accDoc)
	const project = ownProps.accDoc && getAccountingDocumentProject(state, ownProps.accDoc)
	const cashRegister =
		ownProps.accDoc && ownProps.accDoc.cashRegisterId && findCashRegister(state, ownProps.accDoc.cashRegisterId)

	const financialAccountsKey =
		ownProps.accDoc &&
		generateKeyFromFinancialAccountsGetParams({
			accountingDocumentType: ownProps.accDoc.type,
			accountingDocumentDirection: ownProps.accDoc.direction,
		})

	return {
		branch: branch && branch.value,
		project: project && project.value,
		cashRegister: cashRegister && cashRegister.name,
		branches: getOrganizationBranches(state),
		projects: getOrganizationProjects(state),
		financialAccounts: getFinancialAccounts(state, financialAccountsKey) || [],
		isVatFree: getIsOrganizationVatFree(state),
		vatRates:
			ownProps.accDoc && ownProps.accDoc.id
				? getAccountingDocumentVatRates(state, ownProps.accDoc.id)
				: ownProps.accDoc
				? getVatRates(state, ownProps.accDoc.direction, ownProps.accDoc.taxDate, ownProps.accDoc.vatCountryType)
				: EMPTY_ARRAY,
		isInternal: currentUserIsInternal(state),
		isIdentified,
		isCzechContact,
	}
}

type DispatchProps = {|
	onBranchChange: (accDocId: string, newDefaults: AccountingDocumentPatchPreferredBranchAndProject) => void,
	onProjectChange: (accDocId: string, newDefaults: AccountingDocumentPatchPreferredBranchAndProject) => void,
	onSetBranchToDefault: (accDocId: string, newLineItem: AccountingDocumentLineItem) => void,
	onSetProjectToDefault: (accDocId: string, newLineItem: AccountingDocumentLineItem) => void,
	autoload: () => void,
	loadFinancialAccounts?: (type: AccountingDocumentType, direction: AccountingDocumentDirection) => void,
|}

type Action = AccountingDocumentReduxAction | CommonAction | UploadScanAction | OrganizationAction
const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): DispatchProps => {
	return {
		onBranchChange: (accDocId: string, newDefaults: AccountingDocumentPatchPreferredBranchAndProject) => {
			dispatch(setAccDocDefaultBranchAndProject(accDocId, newDefaults))
		},
		onProjectChange: (accDocId: string, newDefaults: AccountingDocumentPatchPreferredBranchAndProject) => {
			dispatch(setAccDocDefaultBranchAndProject(accDocId, newDefaults))
		},
		onSetBranchToDefault: (accDocId: string, newLineItem: AccountingDocumentLineItem) => {
			dispatch(updateAccountingDocumentLineItem(accDocId, newLineItem, false))
		},
		onSetProjectToDefault: (accDocId: string, newLineItem: AccountingDocumentLineItem) => {
			dispatch(updateAccountingDocumentLineItem(accDocId, newLineItem, false))
		},
		loadFinancialAccounts: (type: AccountingDocumentType, direction: AccountingDocumentDirection) => {
			dispatch(
				loadFinancialAccounts({
					accountingDocumentType: AccountingDocumentType_Number(type),
					accountingDocumentDirection: AccountingDocumentDirection_Number(direction),
				}),
			)
		},
		autoload: () => {
			ownProps.accDoc &&
				dispatch(
					loadFinancialAccounts({
						accountingDocumentType: ownProps.accDoc.type,
						accountingDocumentDirection: ownProps.accDoc.direction,
					}),
				)
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(ClassificationInfo))
